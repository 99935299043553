
import { computed, inject } from 'vue';
import poa from '@/hooks/poa';
import { mf, plural, ifPlural } from '@/utils/format';
import { medicalPoaKey, ownerPersonKey } from '@/types/commonTypes';

export default {
  setup() {
    const ownerPerson = inject(ownerPersonKey);
    const medicalPoa = inject(medicalPoaKey);

    const {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasPreferences,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
      attorneyHasEmail,
    } = poa(medicalPoa?.value);

    const allAttorneys = computed(() => {
      return [
        ...(primaryAttorneys?.value ?? []),
        ...(hasSecondaries.value ? secondaryAttorneys?.value ?? [] : []),
      ];
    });

    return {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasPreferences,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
      attorneyHasEmail,
      allAttorneys,
      poa: medicalPoa,
      ownerPerson,
    };
  },
  methods: {
    mf,
    plural,
    ifPlural,
  },
};
