
import AppointmentOfAttorneys from './components/financial/AppointmentOfAttorneys.vue';
import Revocation from './components/financial/Revocation.vue';

export default {
  components: {
    AppointmentOfAttorneys,
    Revocation,
  },
};
