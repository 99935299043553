
import { inject } from 'vue';
import { ownerPersonKey } from '@/types/commonTypes';

export default {
  setup() {
    const ownerPerson = inject(ownerPersonKey);

    return { ownerPerson };
  },
};
