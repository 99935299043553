
import { inject } from 'vue';
import poa from '@/hooks/poa';
import { mf, plural, ifPlural } from '@/utils/format';
import { medicalPoaKey, ownerPersonKey } from '@/types/commonTypes';

export default {
  setup() {
    const ownerPerson = inject(ownerPersonKey);
    const medicalPoa = inject(medicalPoaKey);

    const {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasPreferences,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
    } = poa(medicalPoa?.value);

    return {
      actJointly,
      attorneys,
      hasInclusions,
      hasLimitations,
      hasPreferences,
      hasMultipleAttorneys,
      inclusions,
      hasSecondaries,
      othersMayAct,
      primaryAttorneys,
      secondaryAttorneys,
      poa: medicalPoa,
      ownerPerson,
    };
  },
  methods: {
    mf,
    plural,
    ifPlural,
  },
};
