import { computed, ComputedRef } from 'vue';
import { Attorneys, PoaInclusion, PoaResult } from '@/types/powerOfAttorney';
import { mf } from '@/utils/format';

interface PoaData {
  attorneys: ComputedRef<Attorneys[] | undefined>;
  primaryAttorneys: ComputedRef<Attorneys[] | undefined>;
  hasMultipleAttorneys: ComputedRef<boolean>;
  hasSecondaries: ComputedRef<boolean>;
  secondaryAttorneys: ComputedRef<Attorneys[] | undefined>;
  actJointly: ComputedRef<boolean>;
  othersMayAct: ComputedRef<boolean>;
  hasInclusions: ComputedRef<boolean>;
  inclusions: ComputedRef<PoaInclusion[] | undefined>;
  hasLimitations: ComputedRef<boolean>;
  hasPreferences: ComputedRef<boolean>;
  attorneyHasEmail: ComputedRef<boolean>;
}

export default function computedPoaData(poa: PoaResult | undefined): PoaData {
  const attorneys = computed<Attorneys[] | undefined>(() => {
    const hasSubstitute = mf('has_substitute', poa?.meta) === 'true';

    if (hasSubstitute) {
      return poa?.attorneys;
    } else {
      return poa?.attorneys.filter((g: Attorneys) => g && g.role === 'PRIMARY');
    }
  });

  const primaryAttorneys = computed<Attorneys[] | undefined>(() =>
    attorneys.value?.filter((g: Attorneys) => g && g.role === 'PRIMARY')
  );

  const hasMultipleAttorneys = computed<boolean>(
    () => primaryAttorneys.value !== undefined && primaryAttorneys.value?.length > 1
  );

  const hasSecondaries = computed<boolean>(() => mf('has_substitute', poa?.meta) === 'true');

  const secondaryAttorneys = computed<Attorneys[] | undefined>(() =>
    attorneys.value?.filter((g: Attorneys) => g && g.role === 'SECONDARY')
  );

  const actJointly = computed<boolean>(() => mf('attorney_decisions', poa?.meta) === 'jointly');

  const othersMayAct = computed<boolean>(
    () => mf('attorney_decisions_other', poa?.meta) === 'other'
  );

  const hasInclusions = computed<boolean>(() => mf('has_inclusions', poa?.meta) === 'true');

  const inclusions = computed<PoaInclusion[] | undefined>(() => poa?.inclusions);

  const hasLimitations = computed<boolean>(() => mf('has_limitations', poa?.meta) === 'true');

  const hasPreferences = computed<boolean>(() => mf('has_preferences', poa?.meta) === 'true');

  const attorneyHasEmail = computed<boolean>(() => mf('email', poa?.meta) !== '');

  return {
    attorneys,
    primaryAttorneys,
    hasMultipleAttorneys,
    hasSecondaries,
    secondaryAttorneys,
    actJointly,
    othersMayAct,
    hasInclusions,
    inclusions,
    hasLimitations,
    hasPreferences,
    attorneyHasEmail,
  };
}
