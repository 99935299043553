import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Poa = _resolveComponent("Poa")!

  return ($setup.error)
    ? (_openBlock(), _createElementBlock("code", _hoisted_1, _toDisplayString($setup.error), 1))
    : ($setup.isPoaLoaded)
      ? (_openBlock(), _createBlock(_component_Poa, {
          key: 1,
          ownerPerson: $setup.detokenisedOwnerPerson,
          financialPoa: $setup.detokenisedFinancialPoa,
          medicalPoa: $setup.detokenisedMedicalPoa
        }, null, 8, ["ownerPerson", "financialPoa", "medicalPoa"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, "loading data..."))
}