import Skyflow from 'skyflow-js';
import { IRevealResponseType } from 'skyflow-js/types/utils/common';

type DetokenisedTokens = Record<string, string>;
type TokenisedData<T> = T;
type DetokenisedData<T> = TokenisedData<T>;

export async function detokenise<T>(tokenisedData: TokenisedData<T>): Promise<DetokenisedData<T>> {
  const accessToken = getPrivacyToken();

  if (!accessToken) {
    throw new Error('Skyflow Error - Access token not provided');
  }

  const tokens = getTokensToDetokenise<T>(tokenisedData);

  if (tokens.length === 0) {
    return tokenisedData;
  }

  const detokenisedTokens = await detokeniseTokens(accessToken, tokens);

  return transformTokenisedData(tokenisedData, detokenisedTokens);
}

function getPrivacyToken() {
  const url = new URL(window.location.href);
  return url.searchParams.get('privacyToken');
}

/**
 * Recursively search through the data object and return a list of tokens to detokenise
 * @param {object|Array|string} data
 * @returns {string[]} tokens
 */
function getTokensToDetokenise<T>(data: TokenisedData<T>): string[] {
  const tokens: string[] = [];

  if (data === null || data === undefined) {
    return tokens;
  } else if (typeof data === 'string' && data?.startsWith('token|')) {
    tokens.push(data);
  } else if (Array.isArray(data)) {
    tokens.push(...data.flatMap((item) => getTokensToDetokenise(item)));
  } else if (typeof data === 'object') {
    tokens.push(...Object.values(data).flatMap((value) => getTokensToDetokenise(value)));
  }

  return Array.from(new Set(tokens));
}

/**
 * Recursively transform the tokenised data object to detokenised data object
 * with the same format as the original data object
 * @param {object|Array|string} tokenisedData
 * @param {object} detokenisedTokens
 * @returns {object|Array|string} detokenisedData
 */
function transformTokenisedData<T>(
  tokenisedData: TokenisedData<T>,
  detokenisedTokens: Record<string, string>
): DetokenisedData<T> {
  let detokenisedData;

  if (tokenisedData === null || tokenisedData === undefined) {
    detokenisedData = tokenisedData;
  } else if (typeof tokenisedData === 'string') {
    detokenisedData = detokenisedTokens[tokenisedData] ?? tokenisedData;
  } else if (Array.isArray(tokenisedData)) {
    detokenisedData = tokenisedData.map((value) =>
      transformTokenisedData(value, detokenisedTokens)
    ) as DetokenisedData<T>;
  } else if (typeof tokenisedData === 'object') {
    detokenisedData = Object.fromEntries(
      Object.entries(tokenisedData).map(([key, value]) => [
        key,
        transformTokenisedData(value, detokenisedTokens),
      ])
    );
  } else {
    detokenisedData = tokenisedData;
  }

  return detokenisedData as DetokenisedData<T>;
}

/**
 * Detokenise provided tokens adhering to format of 'token|<skyflow token>' provided a
 * skyflow token with appropriate permissions to detokenise data from the table it
 * originates from.
 * @async
 * @function detokeniseTokens
 * @param {string} accessToken
 * @param {string[]} tokens
 * @returns {Promise<object>} detokenisedList - { 'token|<skyflow token>': 'detokenised value' }
 */
async function detokeniseTokens(accessToken: string, tokens: string[]): Promise<DetokenisedTokens> {
  // We just pass the token already generated into the getBearerToken function
  const skyflow = Skyflow.init({
    vaultID: process.env.VUE_APP_SKYFLOW_ID,
    vaultURL: process.env.VUE_APP_SKYFLOW_URL,
    getBearerToken: () => {
      return Promise.resolve(accessToken);
    },
  });

  if (!skyflow) {
    throw new Error('Skyflow Error - Connection failed');
  }

  const tokenChunks = [];
  const maxTokenListSize = 25; // Service account is limited to 25 tokens in one request

  for (let index = 0; index < tokens.length; ) {
    tokenChunks.push(tokens.slice(index, index + maxTokenListSize));
    index += maxTokenListSize;
  }

  const detokenisedResults: IRevealResponseType[] = [];
  for (let index = 0; index < tokenChunks.length; ) {
    const detokenisedChunks = await Promise.all(
      tokenChunks.slice(index, index + 5).map((tokenList) => {
        return skyflow.detokenize({
          records: tokenList.map((token) => ({
            token: token.replace('token|', ''),
          })),
        });
      })
    );
    detokenisedResults.push(...detokenisedChunks);
    index += 5;
  }

  const detokenisedList: DetokenisedTokens = {};
  const errorList: Object[] = [];

  detokenisedResults.forEach((result) => {
    if (result.errors) {
      errorList.push(...result.errors);
    }
    if (result.records) {
      result.records.forEach((record) => {
        detokenisedList[`token|${record.token}`] = record.value;
      });
    }
  });

  if (errorList.length) {
    console.error('Errors thrown from Skyflow: ', JSON.stringify(errorList));
    throw new Error('Skyflow Error - Error detokenising');
  }

  return detokenisedList;
}
