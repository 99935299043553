
import AppointmentOfAttorneys from './components/medical/AppointmentOfAttorneys.vue';
import Revocation from './components/medical/Revocation.vue';

export default {
  components: {
    AppointmentOfAttorneys,
    Revocation,
  },
};
