const DirectoryPersonFragment = `
  fragment DirectoryPerson on DirectoryPerson {
    id
    firstName
    middleName
    lastName
    altFirstName
    altMiddleName
    altLastName
    dateOfBirth
    countryOfBirth
    emails {
      type
      value
      verified
    }
    phoneNumbers {
      type
      prefix
      value
    }
    residentialAddress {
      streetAddress
      extendedAddress
      locality
      postcode
      region
      country
    }
    ageEighteenOrAbove
  }
`;

export const CURRENT_USER = `
  query currentUser {
    currentUser {
      message
      user {
        id
        person_id
      }
    }
  }
`;

export const GET_PERSON_BY_ID = `
  ${DirectoryPersonFragment}
  query getDirectoryPerson($id: ID!) {
    getDirectoryPerson(id: $id) {
      ...DirectoryPerson
    }
  }
`;

export const GET_WILL = `
  query {
    getWill {
      will {
        email
        id
        meta {
          key
          value
        }
      }
    }
  }
`;

export const GET_MEDICAL_POA = `
    ${DirectoryPersonFragment}
    query GetPoa {
        poa {
            id
            hashId
            status
            meta(category: MEDICAL) {
                id
                key
                value
                category
            }
            attorneys(power: MEDICAL) {
                id
                power
                role
                directoryPerson {
                    ...DirectoryPerson
                }
            }
            inclusions(power: MEDICAL) {
                id
                power
                directoryPerson {
                    ...DirectoryPerson
                }
          }
        }
    }
`;

export const GET_FINANCIAL_POA = `
  ${DirectoryPersonFragment}
  query GetPoa {
      poa {
          id
          hashId
          status
          meta(category: FINANCIAL) {
              id
              key
              value
              category
          }
          attorneys(power: FINANCIAL) {
              id
              power
              role
              directoryPerson {
                  ...DirectoryPerson
              }
          }
          inclusions(power: FINANCIAL) {
              id
              power
              directoryPerson {
                  ...DirectoryPerson
              }
          }
      }
  }
`;

export const CONTACTS = `
  ${DirectoryPersonFragment}
  query GetDirectoryPersons($ownerId: ID!, $excludeSelf: Boolean!) {
    getDirectoryPersonsByOwnerId(ownerId: $ownerId, excludeSelf: $excludeSelf) {
      ...DirectoryPerson
      relationships {
        type
        toPersonId
        fromPersonId
      }
    }
  }
`;
