import FinancialACT from '@/documents/act/Financial.vue';
import MedicalACT from '@/documents/act/Medical.vue';
import FinancialNSW from '@/documents/nsw/Financial.vue';
import MedicalNSW from '@/documents/nsw/Medical.vue';
import FinancialNT from '@/documents/nt/Financial.vue';
import MedicalNT from '@/documents/nt/Medical.vue';
import FinancialQLD from '@/documents/qld/Financial.vue';
import MedicalQLD from '@/documents/qld/Medical.vue';
import FinancialSA from '@/documents/sa/Financial.vue';
import MedicalSA from '@/documents/sa/Medical.vue';
import FinancialTAS from '@/documents/tas/Financial.vue';
import MedicalTAS from '@/documents/tas/Medical.vue';
import FinancialVIC from '@/documents/vic/Financial.vue';
import MedicalVIC from '@/documents/vic/Medical.vue';
import FinancialWA from '@/documents/wa/Financial.vue';
import MedicalWA from '@/documents/wa/Medical.vue';

export default [
  { name: 'FinancialACT', path: '/financial/act', component: FinancialACT },
  { name: 'MedicalACT', path: '/medical/act', component: MedicalACT },
  { name: 'FinancialNSW', path: '/financial/nsw', component: FinancialNSW },
  { name: 'MedicalNSW', path: '/medical/nsw', component: MedicalNSW },
  { name: 'FinancialNT', path: '/financial/nt', component: FinancialNT },
  { name: 'MedicalNT', path: '/medical/nt', component: MedicalNT },
  { name: 'FinancialQLD', path: '/financial/qld', component: FinancialQLD },
  { name: 'MedicalQLD', path: '/medical/qld', component: MedicalQLD },
  { name: 'FinancialSA', path: '/financial/sa', component: FinancialSA },
  { name: 'MedicalSA', path: '/medical/sa', component: MedicalSA },
  { name: 'FinancialTAS', path: '/financial/tas', component: FinancialTAS },
  { name: 'MedicalTAS', path: '/medical/tas', component: MedicalTAS },
  { name: 'FinancialVIC', path: '/financial/vic', component: FinancialVIC },
  { name: 'MedicalVIC', path: '/medical/vic', component: MedicalVIC },
  { name: 'FinancialWA', path: '/financial/wa', component: FinancialWA },
  { name: 'MedicalWA', path: '/medical/wa', component: MedicalWA },
];
