
import { useClient, defaultPlugins, definePlugin, useQuery } from 'villus';
import { reactive } from 'vue';
import {
  CURRENT_USER,
  GET_FINANCIAL_POA,
  GET_MEDICAL_POA,
  GET_PERSON_BY_ID,
} from '@/utils/queries';
import { detokenise } from '@/utils/detokenise';
import Poa from './Poa.vue';

declare const process: {
  env: {
    VUE_APP_SAFEWILL_BACKEND: string;
    VUE_APP_SKYFLOW_ID: string;
    VUE_APP_SKYFLOW_URL: string;
  };
};

export default {
  name: 'App',
  components: { Poa },
  async setup() {
    const API_URL = process.env.VUE_APP_SAFEWILL_BACKEND;

    console.info('Making requests against ' + API_URL);

    const retrieveAuthToken = () => {
      const url = new URL(window.location.href);
      return url.searchParams.get('auth');
    };

    const authPlugin = () => {
      return definePlugin(({ opContext }) => {
        opContext.headers.Authorization = `Bearer ${retrieveAuthToken()}`;
      });
    };

    useClient({
      url: API_URL,
      use: [authPlugin(), ...defaultPlugins()],
    });

    // Get user's details
    const getOwnerPersonVariables = reactive({ id: undefined });
    const { error: userDataError } = await useQuery({
      query: CURRENT_USER,
      onSuccess: (data) => {
        getOwnerPersonVariables.id = data?.currentUser?.user?.person_id;
      },
    });
    const { data: ownerPerson, error: userPersonDataError } = await useQuery({
      query: GET_PERSON_BY_ID,
      variables: getOwnerPersonVariables,
      paused: () => !getOwnerPersonVariables.id,
    });

    // Get POA data
    const { data: financialPoaData, error: financialPoaDataError } = await useQuery({
      query: GET_FINANCIAL_POA,
    });
    const { data: medicalPoaData, error: medicalPoaDataError } = await useQuery({
      query: GET_MEDICAL_POA,
    });

    const poa = reactive({
      data: {
        ownerPerson: undefined,
        financialPoa: undefined,
        medicalPoa: undefined,
      },
    });

    poa.data = await detokenise({
      ownerPerson: ownerPerson.value?.getDirectoryPerson,
      financialPoa: financialPoaData.value?.poa,
      medicalPoa: medicalPoaData.value?.poa,
    });

    return {
      isPoaLoaded: ownerPerson && medicalPoaData && financialPoaData,
      detokenisedOwnerPerson: poa.data.ownerPerson,
      detokenisedFinancialPoa: poa.data.financialPoa,
      detokenisedMedicalPoa: poa.data.medicalPoa,
      error: userDataError || userPersonDataError || financialPoaDataError || medicalPoaDataError,
    };
  },
};
