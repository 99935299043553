import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { registerLocale } from 'i18n-iso-countries';

import AppWrapper from './AppWrapper.vue';
import './index.css';
import routes from './routes';

registerLocale(require('i18n-iso-countries/langs/en.json'));

const app = createApp(AppWrapper);
const router = createRouter({
  routes,
  history: createWebHistory(),
});

app.use(router);
app.mount('#app');
