
import { computed, provide } from 'vue';
import { financialPoaKey, medicalPoaKey, ownerPersonKey } from '@/types/commonTypes';
import { enrichPersonData } from '@/utils/format';
import { DirectoryPerson, PoaResult } from '@/types/powerOfAttorney';

export default {
  name: 'Poa',
  props: {
    ownerPerson: {
      type: Object,
      required: true,
    },
    financialPoa: {
      type: Object,
      required: true,
    },
    medicalPoa: {
      type: Object,
      required: true,
    },
  },
  setup(props: any) {
    const enrichPOAData = (poa: PoaResult): PoaResult => {
      return {
        ...poa,
        attorneys: poa.attorneys.map((attorney) => ({
          ...attorney,
          directoryPerson: enrichPersonData(attorney.directoryPerson),
        })),
        inclusions: poa.inclusions.map((inclusion) => ({
          ...inclusion,
          directoryPerson: enrichPersonData(inclusion.directoryPerson),
        })),
      };
    };

    provide(
      ownerPersonKey,
      computed(() => enrichPersonData(props.ownerPerson as DirectoryPerson))
    );
    provide(
      financialPoaKey,
      computed(() => enrichPOAData(props.financialPoa as PoaResult))
    );
    provide(
      medicalPoaKey,
      computed(() => enrichPOAData(props.medicalPoa as PoaResult))
    );
  },
};
