import { EnrichedDirectoryPerson, PoaResult } from '@/types/powerOfAttorney';
import { WillResult } from '@/types/will';
import { Ref } from 'vue';
// eslint-disable-next-line prettier/prettier
import type { InjectionKey } from 'vue';

export interface CommonProps {
  poa?: PoaResult;
  will?: WillResult;
}

export const ownerPersonKey: InjectionKey<Ref<EnrichedDirectoryPerson>> = Symbol('ownerPersonKey');
export const financialPoaKey: InjectionKey<Ref<PoaResult>> = Symbol('financialPoaKey');
export const medicalPoaKey: InjectionKey<Ref<PoaResult>> = Symbol('medicalPoaKey');
