import dayjs from 'dayjs';
import { getName } from 'i18n-iso-countries';
import {
  DirectoryAddress,
  DirectoryPerson,
  EnrichedDirectoryPerson,
} from '@/types/powerOfAttorney';

export function mf(field: any, arr: any): any {
  if (!arr || !arr.length) return null;
  let val = '';
  arr.forEach((a: any) => {
    if (a.key === field) val = a.value;
  });
  return val;
}

export function name(meta: any, prefix = ''): any {
  return `${mf(`${prefix}name_first`, meta)} ${
    mf(`${prefix}name_middle`, meta) ? ` ${mf(`${prefix}name_middle`, meta)}` : ''
  } ${mf(`${prefix}name_last`, meta)}`;
}

export function getCountryName(countryCode: string | undefined) {
  if (!countryCode) return '';
  return getName(countryCode, 'en');
}

export function isValidAddress(residentialAddress: DirectoryAddress) {
  const { streetAddress, locality, postcode, region, country } = residentialAddress;
  return !!(streetAddress && locality && postcode && region && country);
}

export function buildAddress(residentialAddress: DirectoryAddress | undefined): string {
  if (!residentialAddress) return '';

  const address = [
    residentialAddress.streetAddress,
    residentialAddress.locality,
    residentialAddress.region,
    residentialAddress.postcode,
    getCountryName(residentialAddress.country),
  ]
    .filter(Boolean)
    .join(', ');

  return `of ${address}`;
}

export function dateOfBirthFormatted(dateOfBirth: string): string {
  if (!dateOfBirth) return '';
  return dayjs(dateOfBirth).format('D MMMM YYYY');
}

export function identifiers(directoryPerson: DirectoryPerson) {
  let addressIdentifier: string | undefined;
  const otherIdentifiers: string[] = []; // DOB, Phone number, Email

  if (isValidAddress(directoryPerson.residentialAddress)) {
    addressIdentifier = buildAddress(directoryPerson.residentialAddress);
  }

  if (directoryPerson.dateOfBirth) {
    otherIdentifiers.push(`DOB: ${dateOfBirthFormatted(directoryPerson.dateOfBirth)}`);
  }
  if (directoryPerson.phoneNumbers && directoryPerson.phoneNumbers.length > 0) {
    const defaultPhoneNumber = getPersonDefaultPhoneNumber(directoryPerson);
    if (defaultPhoneNumber) {
      otherIdentifiers.push(`Ph: ${defaultPhoneNumber}`);
    }
  }
  if (directoryPerson.emails && directoryPerson.emails.length > 0) {
    const defaultEmail = directoryPerson.emails.filter(({ type }) => type === 'DEFAULT')[0].value;
    otherIdentifiers.push(`Email: ${defaultEmail}`);
  }

  if (addressIdentifier && otherIdentifiers.length > 0) {
    return `${addressIdentifier} (${otherIdentifiers.join(', ')})`;
  }

  return addressIdentifier ?? `(${otherIdentifiers.join(', ')})`;
}

export function buildDirectoryPersonFullName(directoryPerson: DirectoryPerson) {
  return [
    directoryPerson.firstName,
    ...(directoryPerson.middleName ? [directoryPerson.middleName] : []),
    directoryPerson.lastName,
  ].join(' ');
}

export function buildDirectoryPersonAltFullName(directoryPerson: DirectoryPerson) {
  return [
    directoryPerson.altFirstName,
    ...(directoryPerson.altMiddleName ? [directoryPerson.altMiddleName] : []),
    directoryPerson.altLastName,
  ].join(' ');
}

export function getPersonDefaultEmailAddress(directoryPerson: DirectoryPerson): string | undefined {
  return directoryPerson.emails?.find((email) => email.type === 'DEFAULT')?.value;
}
export function getPersonDefaultPhoneNumber(directoryPerson: DirectoryPerson): string | undefined {
  const defaultPhoneNumber = directoryPerson.phoneNumbers?.find(
    (phone) => phone.type === 'DEFAULT'
  );
  if (defaultPhoneNumber) {
    return `${defaultPhoneNumber.prefix ?? ''}${defaultPhoneNumber.value}`;
  }
}

export function enrichPersonData(directoryPerson: DirectoryPerson): EnrichedDirectoryPerson {
  return {
    ...directoryPerson,
    fullName: buildDirectoryPersonFullName(directoryPerson),
    altFullName: buildDirectoryPersonAltFullName(directoryPerson),
    defaultEmailAddress: getPersonDefaultEmailAddress(directoryPerson),
    defaultPhoneNumber: getPersonDefaultPhoneNumber(directoryPerson),
    fullAddress: buildAddress(directoryPerson.residentialAddress),
    identifiers: identifiers(directoryPerson),
    dateOfBirthFormatted: dateOfBirthFormatted(directoryPerson.dateOfBirth ?? ''),
  };
}

export function ifPlural(strTrue: string, strFalse: string, data: Array<any>): string {
  return data?.length > 1 ? strTrue : strFalse;
}

export function plural(str: string, data: Array<any>, repl = 's'): string {
  return data?.length > 1 ? `${str}${repl}` : str;
}
